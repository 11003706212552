<template>
  <div class="w-full container ">
    <div :class="{ hidden: breadcrumbsHidden }">
      <vf-breadcrumbs
        :class="brandClasses.breadcrumbs"
        :first-step="{ label: $t.help, url: localisedPath }"
        :last-step="{ label: currentPageTitle }"
      />
      <h1 v-if="titleBelowBreadcrumb" class="mb-2 flex text-2xl <lg:mb4 <lg:mt-8">
        {{ currentPageTitle }}
      </h1>
    </div>
    <div :class="brandClasses.layout">
      <help-sidebar :class="[{ hidden: navigationHidden }, brandClasses.sidebar]" />
      <nuxt-page class="min-w-0" @change-page-title="currentPageTitle = $event" />
    </div>
  </div>
</template>

<script lang="ts" setup>
const { brandClasses, titleBelowBreadcrumb, hideSidebarPaths } = useAppConfig().pages.help || {}
definePageMeta({
  middleware() {
    const { enableHelpPages } = useFeatureFlags()

    if (!enableHelpPages) return navigateTo('/')
  },
  scrollToTop: true
})

useHead({
  bodyAttrs: {
    class: '[&_footer]:print:hidden [&_header]:print:hidden'
  }
})

const localisedPath = useLocalisedPath('/help')
const route = useRoute()

const breadcrumbsHidden = ref()
const currentPageTitle = ref('')
const navigationHidden = ref()

const hideSidebarPathsLocalised = hideSidebarPaths?.map((path) => useLocalisedPath(path))

watch(() => route.path, () => {
  breadcrumbsHidden.value = route.path.replace(/\/$/, '').endsWith(localisedPath)
  navigationHidden.value = hideSidebarPathsLocalised?.some((path) => route.path.replace(/\/$/, '').endsWith(path))
}, { immediate: true })
</script>
